.wrapper {
  width: 100%;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  margin-top: 20px;
  .content {
    padding: 10px 25px 20px 25px;
    .item {
      margin-top: 15px;
      .line {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          display: flex;
          gap: 15px;
          align-items: center;
          .name {
            font-family: "Manrope";
            font-weight: 700;
            font-size: 22px;
            letter-spacing: -4%;
          }
        }
        .code {
          font-weight: 500;
          font-size: 14px;
          color: #707072;
        }
      }
      .variants {
        display: flex;
        flex-direction: column;
        margin-top: -10px;
        .variant {
          font-size: 14px;
          line-height: 117%;
          color: #5d6072;
          margin-left: 40px;
          padding: 15px 0;
          letter-spacing: -3%;
        }
        .border {
          border-bottom: 1px solid #d0cfd3;
        }
      }
    }
  }
  .empty {
    color: #98989b;
    font-size: 18px;
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
}
