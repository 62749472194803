.menuWrapper {
  height: 60px;
  width: 100%;
  background-color: #fff;
  border-radius: 24px 24px 0 0;
  position: fixed;
  bottom: 5px;
  font-family: "SFProText";
  .items {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin: 0 auto;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a8a8a8;
      cursor: pointer;
      max-width: 33.3%;
      width: 33.3%;
      gap: 8px;
      line-height: 100%;
      letter-spacing: -0.86px;
      font-size: 13px;

    }
  }
}

.active {
  color: #000 !important;
}

.activeNavLine {
  height: 5px;
  background-color: #c5c5c5;
}
