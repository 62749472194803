.wrapper {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  margin-top: 20px;
  padding-bottom: 60px;
  .navigation {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    gap: 10px;
    padding-bottom: 20px;

    .steps {
      color: #939393;
      font-weight: 600;
    }

    .progressBar {
      position: relative;
      height: 4px;
      background-color: #a2a0a9;
      width: 160px;
      border-radius: 30px;

      .progressBarInner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: black;
        transition: width 0.3s ease;
        border-radius: 30px;
      }
    }

    .buttons {
      display: flex;
      gap: 30px;
      margin-left: 30px;
    }
  }
}

.details {
  width: 85%;
  margin: 0 auto;
  .header {
    position: relative;
    .content {
      position: absolute;
      top: 15px;
      left: 15px;
      .title {
        font-size: 36px;
        font-family: "Manrope", sans-serif;
        font-weight: 500;
        letter-spacing: -5%;
      }
      .desc {
        display: flex;
        gap: 10px;
        align-items: center;
        p {
          font-size: 15px;
        }
        span {
          font-size: 15px;
          font-weight: 500;
          color: #b2b2b2;
        }
      }
    }
    .flag {
      position: absolute;
      top: 15px;
      right: 10px;
    }
  }

  .body {
    margin-top: -5.1px;
    margin-left: 0.54688px;
    width: 342px;
    background-color: #f4f4f6;
    padding: 10px;
    height: fit-content;
    border-radius: 0px 0px 10px 10px;
    h3 {
      font-size: 13px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-family: "Manrope", sans-serif;
      font-weight: 900;
      letter-spacing: 4%;
    }
    .info {
      padding-bottom: 15px;
    }
    .comissions {
      padding: 15px 0;
      border-top: 1px solid #e0e0e2;
    }
    .limits {
      padding: 15px 0;
      border-top: 1px solid #e0e0e2;
    }
    .settlement {
      padding: 15px 0;
      border-top: 1px solid #e0e0e2;
    }
    .line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      gap: 20px;
      p {
        width: 40%;
        color: #7a7a7b;
        font-size: 14px;
        letter-spacing: -2%;
        line-height: 110%;
      }
      span {
        width: 60%;
        font-size: 14px;
        // display: flex;
        // justify-content: flex-start;
        // align-items: flex-start;
        letter-spacing: -2%;
        line-height: 160%;
      }
    }
  }
}
