.wrapper {
}

.content {
  z-index: 1;
  animation: textMove 1.3s alternate;
  padding: 26px 26px 0 26px;
  text-align: left;

  .logo {
  }

  .title {
    font-size: 48px;
    font-family: "IBMPlexSans";
    line-height: 85%;
    margin-top: 35px;
  }

  .desc {
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #9a97a9;
    margin-top: 20px;
    span {
      color: black;
    }
  }
}

.container {
  display: flex;
  width: 100%;
  height: 530px;
  justify-content: flex-end;
  align-items: flex-end;

  .image {
    position: relative;
    width: 375px;
    height: 530px;
    // border: 1px solid red;
    overflow-x: hidden !important;
    .hand {
      position: absolute;
      right: -75px;
      top: 15px;
      animation: handMove 1.3s alternate;
    }
    .card {
      position: absolute;
      top: 95px;
      left: 25px;
      z-index: 3;
      animation: cardMove 1.3s alternate;
    }

    .tCoin {
      position: absolute;
      right: 15px;
      animation: tCoinMove 1.3s alternate;
    }

    .end {
      position: absolute;
      animation: endMove 1.3s alternate;
      fill: #ebf442;
      bottom: 195px;
      left: 70px;
      z-index: 2;
    }

    .PercCoin {
      position: absolute;
      bottom: 165px;
      z-index: 1;
      animation: PercCoin 1.3s alternate;
    }
  }
}

@keyframes handMove {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes textMove {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes cardMove {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }

  50% {
    transform: translate(16px, 0);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes tCoinMove {
  0% {
    transform: translate(10px, 10px);
    opacity: 0;
  }

  50% {
    transform: translate(10px, 10px);
    opacity: 0.5;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes endMove {
  0% {
    fill: #e0dfe3;
    transform: translateY(30px);
    opacity: 0;
    bottom: 190px;
    left: 44px;
  }

  40% {
    fill: #e0dfe3;
    bottom: 220px;
    left: 74px;
  }

  70% {
    fill: #e0dfe3;
    bottom: 220px;
    left: 74px;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    fill: #ebf442;
  }
}

@keyframes PercCoin {
  0% {
    transform: translate(0px, 0px);
    opacity: 0;
  }

  50% {
    transform: translate(-15px, -15px);
    opacity: 1;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}
