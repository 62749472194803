body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e0dfe3;

}


html, body {
  height: 100vh;
}

@font-face {
  font-family: "Manrope";
  font-weight: 600;
  src: url("/fonts/Manrope-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope";
  font-weight: 700;
  src: url("/fonts/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope";
  font-weight: 500;
  src: url("/fonts/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope";
  font-weight: 800;
  src: url("/fonts/Manrope-ExtraBold.ttf") format("truetype");
}


@font-face {
  font-family: "IBMPlexSans";
  src: url(/fonts/IBMPlexSans-SemiBold.ttf);
}
@font-face {
  font-family: "SFProText";
  src: url(/fonts/SFProText-Semibold.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}