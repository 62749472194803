.searchBar {
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
  width: 95%;
  margin: 0 auto;
  background-color: #d3d2d7;
  font-family: "Manrope", sans-serif;
    font-weight: 600;
  .searchIcon {
    margin-left: 10px;
  }
  input {
    font-size: 16px;
    border: 0;
    padding: 13px;
    background-color: #d3d2d7;
    outline: none;
    width: 80%;
  }
}
